import { AxiosResponse } from 'axios'
import axios from '../instance'
import {
	IProductListRes,
	IArgumentProductList,
	IArgumentProductCreation,
	ImportProductDetectPayload,
	ImportProductResult,
	IArgumentProductSetCreation,
	AdjustStockPayload,
	LogParams,
	LogResponse,
	EditStockAndPricePayload,
} from './type'
import { LocationQueryValue } from 'vue-router'

export const getProductList = async (params: IArgumentProductList) => {
	try {
		const { data } = await axios.get('/product', { params: { ...params } })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getProductSetList = async (params: IArgumentProductList) => {
	try {
		const { data } = await axios.get('/product/set/list', {
			params: { ...params },
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const createProduct = async (
	bodyData: IArgumentProductCreation
): Promise<IProductListRes> => {
	try {
		const response = await axios.post('/product/create', bodyData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const createProductSet = async (
	bodyData: IArgumentProductSetCreation
): Promise<IProductListRes> => {
	try {
		const response = await axios.post('/product/set/create', bodyData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getProductDetail = async (
	productId: string | LocationQueryValue | LocationQueryValue[]
) => {
	try {
		const response = await axios.get(`/product/detail/${productId}`)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getProductSetDetail = async (
	productId: string | LocationQueryValue | LocationQueryValue[]
) => {
	try {
		const response = await axios.get(`/product/set/${productId}`)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const editProduct = async (
	productId: string | LocationQueryValue | LocationQueryValue[],
	bodyData: IArgumentProductCreation
): Promise<IProductListRes> => {
	try {
		const response = await axios.put(`/product/edit/${productId}`, bodyData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const editProductSet = async (
	productId: string | LocationQueryValue | LocationQueryValue[],
	bodyData: IArgumentProductSetCreation
): Promise<IProductListRes> => {
	try {
		const response = await axios.put(`/product/set/${productId}`, bodyData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteProductById = async (
	payload: {
		productId: string | LocationQueryValue | LocationQueryValue[],
		isDeleteProductOnMarketplace: boolean
	}
) => {
	try {
		const { data } = await axios.post('/product/delete', payload as any)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const deleteProductSetById = async (
	productId: string | LocationQueryValue | LocationQueryValue[]
) => {
	try {
		const { data } = await axios.delete(`/product/set/${productId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getSKUProduct = async (params: { shopId: string }) => {
	try {
		const response = await axios.get('/product/sku', {
			params: { ...params },
		})
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const importProductDetect = async (
	payload: ImportProductDetectPayload
): Promise<ImportProductResult> => {
	try {
		const { data } = await axios.post('/product/import-detect', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const importProduct = async (
	payload: ImportProductDetectPayload
): Promise<AxiosResponse> => {
	try {
		const { data } = await axios.post('/product/import', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteMultipleProduct = async (
	payload: {
		productIds: string[],
		isDeleteProductOnMarketplace: boolean,
	}
): Promise<any> => {
	try {
		const { data } = await axios.post('/product/delete-multiple', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}


export const getLogProductList = async (
	getParams: LogParams
): Promise<LogResponse> => {
	try {
		const { data } = await axios.get(`/product/log/${getParams.productId}`, { params: getParams })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const adjustStockService = async (
	payload: AdjustStockPayload
): Promise<any> => {
	try {
		const { data } = await axios.put('/product/adjust-stock', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const editStockAndPriceService = async (
	payload: EditStockAndPricePayload
): Promise<any> => {
	try {
		const { data } = await axios.put('/product/edit-stock-price', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const checkProductInProductSetService = async (
	payload: {id:string[],shopId:string}
): Promise<any> => {
	try {
		const { data } = await axios.post('/product/detect-product', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
