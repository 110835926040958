export const PRODUCT_LIST_PATH = '/product'
export const PRODUCT_CREATE_PATH = '/product/create'
export const PRODUCT_SET_CREATE_PATH = '/product/product-set-create'
export const PRODUCT_EDIT_PATH = '/product/edit'
export const PRODUCT_SET_EDIT_PATH = '/product/product-set-edit'
export const ORDER_PATH = '/order'
export const ORDER_CREATE_PATH = '/order/create'
export const ORDER_EDIT_PATH = '/order/edit'
export const ORDER_DETAIL_PATH = '/order/detail'
export const ORDER_CLAIM_PATH = '/order/claim'
export const ORDER_DOWNLOAD_RECRIPT = '/order/order-receipt-payment'
export const SHIPPING_PATH = '/shipping'
export const SHIPPING_ONE_PATH = '/shipping/one'
export const SHIPPING_FOUR_PATH = '/shipping/four'
export const SHIPPING_SIX_PATH = '/shipping/six'
export const SHIPPING_STICKER_PATH = '/shipping/sticker'
export const SHIPPING_STICKER_SIX_PATH = '/shipping/sticker4x6'
export const SHIPPING_PAPERANG_PATH = '/shipping/paperang'
export const SHIPPING_PATH_MARKETPLACE = '/shipping-marketplace'
export const RECEIPT_PATH = '/receipt'
